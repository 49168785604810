<template>
    <div
        :class="{
            'workflow-builder-map-settings pointer-action': true,
        }"
        @click="openModal"
        v-b-tooltip.hover="`Advanced Settings`"
    >
        <i class="icon-settings" />
        <MapSettingsModal v-model="maxConcurrency" :show.sync="showModal" @input="emitInput" />
    </div>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'
import Feedback from '@/components/Feedback.vue'

export default {
    components: {
        WitModal,
        Feedback,
        MapSettingsModal: () => import('./MapSettingsModal.vue'),
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            maxConcurrency: null,
            showModal: false,
        }
    },
    created() {
        this.maxConcurrency = this.value
    },
    methods: {
        openModal() {
            this.showModal = true
        },

        emitInput() {
            this.$emit('input', this.maxConcurrency)
        },
    },
}
</script>

<style lang="scss">
.workflow-builder-map-settings {
    height: 36px;
    line-height: 36px;
    font-weight: 500;

    i {
        font-size: 16px;
    }
}

.workflow-builder-map-settings__modal {
    .modal-body {
        overflow-y: visible;
    }

    legend {
        font-weight: 600;
    }

    .map-concurrency-wrapper {
        width: 100px;
        display: inline-block;
        margin-right: 10px;
    }

    .vue-slider-col {
        padding-top: 8px;
        z-index: 0;
    }
}
</style>
