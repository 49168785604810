<template>
    <div :class="{'workflow-builder-map__wrapper': true, 'workflow-builder-map__wrapper--invalid': displayInvalid}">
        <div class="workflow-builder-map">
            <div class="workflow-builder-map__left">
                <img src="@/assets/workflow/map.svg" />
                <span>Group Task</span>
            </div>
            <div class="workflow-builder-map__right">
                <Pass v-model="localState.passValue" @input="emitInput" />
                <MapSettings v-model="localState.maxConcurrency" @input="emitInput" />
                <i
                    class="workflow-builder-map__remove icon-trash pointer-action"
                    @click="remove"
                    v-b-tooltip.hover="`Remove`"
                />
            </div>
        </div>
        <div class="workflow-builder-map__iterator">
            <Builder
                v-model="localState.iterator"
                in-map
                :key="localState.iterator.startAt"
                @input="emitInput"
                :valid.sync="mapValid"
                :disabled="disabled"
                @update:valid="emitUpdate"
            />
        </div>
    </div>
</template>

<script>
import Pass from './Pass.vue'
import MapSettings from './MapSettings.vue'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Pass,
        MapSettings,
        Builder: () => import('./Index.vue'),
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        stepChecked: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const id = this.$randomId()
        return {
            id,
            localState: {
                name: `Group Task - ${id}`,
                comment: '',
                type: 'Map',
                itemsPath: 'dates',
                iterator: {
                    startAt: '',
                    states: [],
                },
                maxConcurrency: 1,
                passValue: [],
            },
            mapValid: false,
        }
    },
    computed: {
        validComp() {
            return this.mapValid && this.localState.iterator.states.length
        },
        displayInvalid() {
            return this.stepChecked ? !this.validComp : null
        },
    },
    created() {
        if (this.value) {
            this.localState = cloneDeep({...this.localState, ...this.value})
            this.emitInput()
        }
    },
    methods: {
        remove() {
            this.$emit('remove')
        },
        emitInput() {
            this.$emit('input', this.localState)
            this.emitUpdate()
        },
        emitUpdate() {
            this.$emit('update:valid', this.validComp)
        },
    },
}
</script>

<style lang="scss">
.workflow-builder-map__wrapper {
    $self: &;

    &.sortable-chosen {
        .workflow-builder-map {
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
            cursor: grabbing;
        }
    }

    &--invalid#{ $self } {
        .workflow-builder-map {
            box-shadow: rgba(255, 0, 0, 0.3) 0px 1px 6px, rgba(255, 0, 0, 0.3) 0px 1px 4px;
        }
    }

    .workflow-builder-map {
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
        background-color: white;
        padding: 5px 15px;
        margin: 15px 0;

        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        img {
            height: 24px;
            width: 24px;
            margin-right: 10px;
        }

        &__right {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        &__left {
            flex-grow: 1;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: 48px;

            input {
                border: 0;
                padding: 0 100px 0 0;

                &:focus {
                    outline: none;
                }
            }
        }

        i {
            height: 36px;
            line-height: 36px;
            margin-left: 10px;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .workflow-builder-map__iterator {
        padding: 0 0 0 45px;
        // margin: 10px 0 10px 30px;
    }
}
</style>
